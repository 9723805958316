<template>
  <div>
    <div class="vx-row" v-if="!child.pregnant">
      <div class="vx-col mb-4 w-full">
        <label class="vs-input--label">Delivered</label>
        <div class="vs-con-input mt-2">
          <ul class="flex">
            <li class="mx-1">
              <vs-radio v-model="child.delivered"
                        :vs-name="`${prefix}_delivered`"
                name="delivered"
                vs-value="natural"
                v-validate="'required'">
                Natural
              </vs-radio>
            </li>
            <li class="mx-1">
              <vs-radio v-model="child.delivered"
                        :vs-name="`${prefix}_delivered`"
                        name="delivered"
                        vs-value="c-section"
                        v-validate="'required'">
                C-Section
              </vs-radio>
            </li>
          </ul>
        </div>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input v-model="child.name"
          label="Name"
          class="w-full"
          name="child_name"
          color="dark"
          v-validate="'required'"/>
        <span class="text-danger text-sm block" v-show="errors.has('child_name')">{{ errors.first('child_name') }}</span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Gender</label>
        <div class="vs-con-input mt-2">
          <ul class="flex">
            <li class="mx-1">
              <vs-radio v-model="child.gender"
                :vs-name="`${prefix}_gender`"
                name="gender"
                vs-value="boy"
                v-validate="'required'">
                Boy
              </vs-radio>
            </li>
            <li class="mx-1">
              <vs-radio v-model="child.gender"
                :vs-name="`${prefix}_gender`"
                name="gender"
                vs-value="girl"
                v-validate="'required'">
                Girl
              </vs-radio>
            </li>
          </ul>
        </div>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Birthdate</label>
        <datepicker v-model="child.birthdate"
          placeholder="Select Date"
          name="child_birthdate"
          color="dark"
          :format="datePicker.format"
          :disabled-dates="datePicker.disabledDatesForBirthdate"
          v-validate="'required'"/>

          <span class="text-danger text-sm mt-2" v-show="errors.has('child_birthdate')">{{ errors.first('child_birthdate') }}</span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input :value="child.birthdate | age"
          label="Age"
          class="w-full"
          name="child_age"
          color="dark"
          readonly/>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Current Product Using</label>
        <vs-input v-if="product_disabled"
          :value="child_product"
          class="w-full"
          readonly />
        <v-select v-else v-model="child.product"
          class="w-full"
          name="product"
          color="dark"
          :options="optionsProduct"
          :reduce="product => product.slug"
          :disabled="product_disabled" />
        <span class="text-danger text-sm block mt-2" v-show="errors.has('product')">
          {{ errors.first('product') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Buy Product From</label>
        <vs-input v-if="store_disabled"
                  :value="child_store"
                  class="w-full"
                  readonly />
        <v-select v-else v-model="child.store"
                  class="w-full"
                  name="store"
                  color="dark"
                  :options="optionsStore"
                  :reduce="store => store.slug"
                  :disabled="store_disabled" />
        <span class="text-danger text-sm block mt-2" v-show="errors.has('store')">
          {{ errors.first('store') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="child.is_master && child.current_outbound_stage">
        <vs-input
          :value="child.current_outbound_stage.title"
          class="w-full"
          label="Current Stage"
          name="current_stage"
          readonly/>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import formMixin from '@/mixins/member/formMixin'

export default {
  name: 'MemberChildrenDob',
  mixins: [formMixin],
  filters: {
    age (value) {
      if (!value) {
        return ''
      }

      const ageInMonth = moment().diff(value, 'months')

      if (ageInMonth === 0) {
        const day = moment().diff(value, 'days')
        return `${day} day${day > 1 ? 's' : ''}`
      }

      const year = Math.floor(ageInMonth / 12)
      const month = ageInMonth % 12

      const age = []

      if (year > 0) {
        age.push(`${year} year${year > 1 ? 's' : ''}`)
      }

      if (month > 0) {
        age.push(`${month} month${month > 1 ? 's' : ''}`)
      }

      return age.join(' ')
    },
  },
  computed: {
    estimatedDueDate: {
      get () {
        return this.child.estimated_due_date
      },
      set (edd) {
        this.child.estimated_due_date = this.$moment(edd).format('YYYY-MM-DD')
      },
    },
    child_product () {
      const product_slug = this.child.product
      const result = this.optionsProduct.filter(function (p) {
        if (p.slug === product_slug) {
          return p
        }
      })
      if (result.length > 0) {
        return result[0].label
      } else {
        return ''
      }
    },
    child_store () {
      const store_slug = this.child.store
      const result = this.optionsStore.filter(function (p) {
        if (p.slug === store_slug) {
          return p
        }
      })
      if (result.length > 0) {
        return result[0].label
      } else {
        return ''
      }
    },
    product_disabled () {
      return this.child.product !== null
    },
    store_disabled () {
      return this.child.store !== null
    },
  },
}
</script>
