import { Validator } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

const dict = {
  custom: {
    estimated_due_date: {
      required: 'Please select due date',
    },
    child_name: {
      required: 'Please enter child name',
    },
    child_birthdate: {
      required: 'Please select child birthdate',
    },
  },
}

Validator.localize('en', dict)

export default {
  props: {
    child: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Datepicker,
    vSelect,
  },
  data () {
    return {
      dateFormat: 'DD MMM YYYY',
      datePicker: {
        format: 'd MMMM yyyy',
        disabledDatesForBirthdate: {
          from: new Date(),
        },
        disabledDatesForDueDate: {
          to: new Date(),
        },
      },
    }
  },
  computed: {
    ...mapGetters('data', ['products', 'stores']),
    ...mapGetters('member', ['member', 'profile', 'firstChild', 'secondChild']),
    isMaster () {
      return !!this.child.is_master
    },
    currentOutboundStage () {
      return this.child.current_outbound_stage || false
    },
    optionsProduct () {
      // console.log(this.products.map((product) => ({
      //   label: product.title,
      //   slug: product.slug
      // })))

      return this.products.map((product) => ({
        label: product.title,
        slug: product.slug,
      }))
    },
    optionsStore () {
      return this.stores.map((store) => ({
        label: store.title,
        slug: store.slug,
      }))
    },
  },
  methods: {
    ...mapActions('data', ['fetchProducts', 'fetchStores']),
    ...mapActions('member', ['updateOrCreateChild']),
    validate () {
      return this.$validator.validateAll()
    },
    customFormatter (date) {
      return this.$moment(date).format(this.dateFormat)
    },
    save () {
      const child = {
        pregnant: false,
        estimated_due_date: null,
        gender: null,
        name: null,
        birthdate: null,
        delivered: null,
        product: null,
        store: null,
      }

      Object.assign(
        child,
        this.lodash.pick(this.child, Object.keys(child)),
      )

      if (this.lodash.isDate(child.estimated_due_date)) {
        child.estimated_due_date = this.$moment(child.estimated_due_date).format('YYYY-MM-DD')
      }

      // console.log({
      //   birthdate: child.birthdate,
      //   typeof: typeof child.birthdate,
      //   isObject: this.lodash.isObject(child.birthdate),
      //   isEmpty: this.lodash.isEmpty(child.birthdate),
      //   isDate: this.lodash.isDate(child.birthdate),
      //   format: this.$moment(child.birthdate).format('YYYY-MM-DD')
      // })

      if (this.lodash.isDate(child.birthdate)) {
        child.birthdate = this.$moment(child.birthdate).format('YYYY-MM-DD')
      }

      return this.updateOrCreateChild({
        id: this.member.id,
        profileId: this.profile.id,
        childId: this.child.id,
        child,
      })
    },
  },
  beforeMount () {
    if (this.products.length === 0) {
      this.fetchProducts()
    }
    if (this.stores.length === 0) {
      this.fetchStores()
    }
  },
  watch: {
    // products (to) {
    //   console.log({ products: to, optionsProduct: this.optionsProduct })
    // }
  },
}
